<template>
  <div>
    <div class="learning-form-header flex-wrap">
      <h4>LLM Chat Interaction</h4>
      <div class="learning-quiz-btn">
        <button @click="publishLO('published')" class="btn header-btn secondary" v-if="status == 'published'">
          Save
        </button>
        <button v-if="status == 'drafted'" class="btn header-btn primary" @click="publishLO('published')">
          Publish LO
        </button>
      </div>
    </div>
    <div class="learning-lo-tabs">
      <div class="setup-container">
        <ul>
          <li :class="{ active: isActiveTab === 'basic-information' }">
            <button @click="switchTab('basic-information')">
              <span class="check-box"></span>
              <span>Basic Information</span>
            </button>
          </li>
          <li :class="{ active: isActiveTab === 'kb-setup' }">
            <button @click="switchTab('kb-setup')">
              <span class="check-box"></span>
              <span>Knowledge Base Setup</span>
            </button>
          </li>
          <li :class="{ active: isActiveTab === 'prompts-setup' }">
            <button @click="switchTab('prompts-setup')">
              <span class="check-box"></span>
              <span>Prompts Setup</span>
            </button>
          </li>
          <li :class="{ active: isActiveTab === 'chat-testing' }">
            <button @click="switchTab('chat-testing')">
              <span class="check-box"></span>
              <span>Testing</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="learning-quiz-content">
      <!-- Basic Info -->
      <div class="setup-content" v-if="isActiveTab === 'basic-information'">
        <div class="learning-quiz-content">
          <div class="setup-content">
            <div class="d-flex justify-content-between align-items-center">
              <h4 class="head-title">Basic Information</h4>
            </div>
            <ValidationObserver ref="form">
              <form class="w-100">
                <div class="row custom-row">
                  <div class="col-sm-5">
                    <div class="form-group">
                      <label>Interaction title</label>
                      <ValidationProvider name="Interaction Title" rules="required|max:100" v-slot="{ errors }">
                        <input type="text" class="form-control" v-model="name"
                          :class="[isActive && errors[0] ? 'invalid' : '']" />
                        <span :class="[isActive ? 'invalid' : '']" :style="[
          isActive ? { display: 'block' } : { display: 'none' },
        ]" v-if="errors[0]">{{ errors[0].replace("The ", "") }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label>Administrative notes</label>
                      <ValidationProvider name="Administrative Notes" rules="required" v-slot="{ errors }">
                        <textarea type="Description" class="form-control" id="notes" rows="4" v-model="adminNotes"
                          :class="[isActive && errors[0] ? 'invalid' : '']"></textarea>
                        <span :class="[isActive ? 'invalid' : '']" :style="[
          isActive ? { display: 'block' } : { display: 'none' },
        ]" v-if="errors[0]">{{ errors[0].replace("The ", "") }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label>Character Name</label>
                      <ValidationProvider name="Administrative Notes" rules="required" v-slot="{ errors }">
                        <input type="text" class="form-control" id="character-name" v-model="characterName"
                          :class="[isActive && errors[0] ? 'invalid' : '']" placeholder="Add your character's name" />
                        <span :class="[isActive ? 'invalid' : '']" :style="[
          isActive ? { display: 'block' } : { display: 'none' },
        ]" v-if="errors[0]">{{ errors[0].replace("The ", "") }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label>Character Pronouns</label>
                      <ValidationProvider name="Administrative Notes" rules="required" v-slot="{ errors }">
                        <input type="text" class="form-control" id="character-name" v-model="characterPronouns"
                          :class="[isActive && errors[0] ? 'invalid' : '']" placeholder="Add your character's name" />
                        <span :class="[isActive ? 'invalid' : '']" :style="[
          isActive ? { display: 'block' } : { display: 'none' },
        ]" v-if="errors[0]">{{ errors[0].replace("The ", "") }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label>Character Age</label>
                      <ValidationProvider name="Administrative Notes" rules="required" v-slot="{ errors }">
                        <input type="text" class="form-control" id="character-age" v-model="characterAge"
                          :class="[isActive && errors[0] ? 'invalid' : '']" placeholder="Add your character's age" />
                        <span :class="[isActive ? 'invalid' : '']" :style="[
          isActive ? { display: 'block' } : { display: 'none' },
        ]" v-if="errors[0]">{{ errors[0].replace("The ", "") }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label>Character Job</label>
                      <ValidationProvider name="Administrative Notes" rules="required" v-slot="{ errors }">
                        <input type="text" class="form-control" id="character-job" v-model="characterJob"
                          :class="[isActive && errors[0] ? 'invalid' : '']" placeholder="Add your character's job" />
                        <span :class="[isActive ? 'invalid' : '']" :style="[
          isActive ? { display: 'block' } : { display: 'none' },
        ]" v-if="errors[0]">{{ errors[0].replace("The ", "") }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label>Character Image</label>
                      <img v-if="characterImageURL" :src="characterImageURL" class="character-image" />
                      <button v-if="characterImageURL" @click="deleteCharacterImage" class="btn header-btn secondary small-btn">Delete Character Image</button>
                      <input v-if="!characterImageURL" style="display: none" ref="fileInput" type="file" @change="uploadImage"
                        accept="image/jpeg, image/png" />
                      <a v-if="!characterImageURL" @click="$refs.fileInput.click()" class="btn header-btn secondary small-btn"
                        >
                        Upload Character Image
                      </a>
                    </div>
                    <div class="form-group">
                      <label>Character Stance</label>
                      <div>
                        <div>
                          <label>Correct/Incorrect</label>
                          <input v-model="characterStance" type="radio" name="correct/incorrect" value="correct" />
                        </div>
                        <div>
                          <label>Opinion/Argument</label>
                          <input v-model="characterStance" type="radio" name="opinion/argument" value="opinion" />
                        </div>
                        <div>
                          <label>I understand/I don't understand</label>
                          <input v-model="characterStance" type="radio" name="correct/incorrect" value="understand" />
                        </div>
                        <div>
                          <label>GPT</label>
                          <input v-model="characterStance" type="radio" name="gpt" value="gpt" />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="form-label">Conversation Type</label>
                      <select class="custom-select" @change="setCharacteristics($event)">
                        <option v-for="option in optionsCharacteristics" :key="option.id" :value="option.text"
                          :selected="option.text === characterCharacteristics">
                          {{ option.text }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label class="form-label">Feedback Type</label>
                      <select class="custom-select capitalize-option" @change="setFeedbackType($event)">
                        <option v-for="option in optionsFeedbackType" :key="option.id" :value="option.text"
                          :selected="option.text === characterFeedbackType">
                          {{ option.text }}
                        </option>
                      </select>
                    </div>
                    <!-- <div class="form-group custom-control custom-checkbox">
                      <input class="custom-control-input" type="checkbox" v-model="llmChatImageEnabled"
                        id="llmChatImageEnabled" />
                      <label class="custom-control-label" for="llmChatImageEnabled">Image Chat LLM mode</label>
                    </div>
                    <div>
                      <img :src="llmChatImageURL" v-if="llmChatImageEnabled" />
                      <input style="display: none" ref="fileInput" type="file" @change="uploadImage"
                        accept="image/jpeg, image/png" v-if="llmChatImageEnabled" />
                      <a @click="$refs.fileInput.click()" class="btn header-btn secondary small-btn"
                        v-if="llmChatImageEnabled">
                        Upload Image Chat LLM
                      </a>
                    </div> -->
                    <div class="form-group custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" v-model="enableVoice" id="enableVoice" />
                      <label class="custom-control-label" for="enableVoice">Enable Voice</label>
                    </div>
                    <div class="form-group custom-control custom-checkbox">
                      <input class="custom-control-input" type="checkbox" v-model="enableAvatar" id="enableAvatar" />
                      <label class="custom-control-label" for="enableAvatar">Enable Avatar</label>
                    </div>
                    <div class="form-group custom-control custom-checkbox">
                      <input class="custom-control-input" type="checkbox" v-model="showChatLog" id="showChatLog" />
                      <label class="custom-control-label" for="showChatLog">Show Chat Log</label>
                    </div>
                    <div class="form-group custom-control custom-checkbox">
                      <input class="custom-control-input" type="checkbox" v-model="enableLlmAssessments"
                        id="enableLlmAssessments" />
                      <label class="custom-control-label" for="enableLlmAssessments">Enable LLM Assessments</label>
                    </div>
                    <div class="form-group custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" v-model="reattemptsEnabled"
                        id="reattemptsEnabled" />
                      <label class="custom-control-label" for="reattemptsEnabled">Enable Reattempts</label>
                    </div>
                    <div class="form-group custom-control" v-if="reattemptsEnabled">
                      <input type="number" v-model="maxReattempts" id="maxReattempts" placeholder="Max Reattempts" />
                    </div>
                  </div>
                  <div class="col-sm-7">
                    <div class="form-group">
                      <label>Learner Description</label>
                      <ValidationProvider name="Description" rules="required" v-slot="{ errors }">
                        <vue-editor :class="[
          isActive && errors[0]
            ? 'form-control invalid'
            : 'form-control',
        ]" id="Description" placeholder="Add a Description" v-model="description"></vue-editor>
                        <span :class="[isActive ? 'invalid' : '']" :style="[
          isActive ? { display: 'block' } : { display: 'none' },
        ]" v-if="errors[0]">{{ errors[0].replace("The ", "") }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label>Personality Baseline</label>
                      <div class="d-flex flex-row">
                        <div class="d-flex flex-column">
                          <label>Sentiment</label>
                          <input type="text" v-model="promptReactionSentiment" />
                        </div>
                        <div class="d-flex flex-column">
                          <label>Emotion</label>
                          <input type="text" v-model="promptReactionEmotion" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 pt-5">
                    <div class="d-flex justify-content-end mb-4">
                      <button href="javascript:void(0);" class="btn primary" @click="saveLO('drafted', 1)">
                        Save
                      </button>
                    </div>
                    <div class="d-flex justify-content-end mb-4">
                      <button class="btn btn-secondary text" @click="archiveLO('archived')">
                        Archive this Learning Object(LO)
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <div class="setup-content" v-if="isActiveTab === 'kb-setup'">
        <div class="learning-quiz-content">
          <div class="setup-content">
            <div class="d-flex justify-content-between align-items-center">
              <h4 class="head-title">Knowledge Base Setup</h4>
            </div>
            <div class="knowledge-base-container">
              <div class="knowledge-base-item">
                <h5>General Knowledge base</h5>
                <textarea class="text-area" :placeholder="`Add general knowledge base`"
                  v-model="generalKnowledgeBase"></textarea>
              </div>
              <div class="knowledge-base-item">
                <h5>Global Narrative Knowledge base</h5>
                <textarea class="text-area narrativeKnowledgeBaseItem" :placeholder="`Add narrative knowledge base`"
                  v-model="narrativeKnowledgeBase"></textarea>
              </div>
            </div>
            <div class="kb-btn-container">
              <button class="btn primary" @click="saveLO('drafted', 1)">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <LlmChatSkillAssessment v-if="isActiveTab === 'prompts-setup' && this.enableLlmAssessments"
        :llmChatData="llmChatData" :llmType="llmType" :status="status" @saveLO="saveLO" @archiveLO="archiveLO" />
      <div class="setup-content" v-if="isActiveTab == 'prompts-setup' && !this.enableLlmAssessments">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="head-title">Prompts Setup</h4>
        </div>
        <!-- <div class="prompts-container prompt-reaction-container">
          <div class="prompts-header">
            <h5>Prompt Reaction Settings</h5>
          </div>
          <div class="prompts-body">
            <div class="form-group">
              <label>Sentiment</label>
              <ValidationProvider
                name="Prompt Reaction Sentiment"
                rules="required"
                v-slot="{ errors }"
              >
                <input
                  type="text"
                  class="form-control"
                  id="character-characteristics"
                  v-model="promptReactionSentiment"
                  :class="[isActive && errors[0] ? 'invalid' : '']"
                  placeholder="Add your Prompt Reaction Sentiment"
                />
                <span
                  :class="[isActive ? 'invalid' : '']"
                  :style="[
                    isActive ? { display: 'block' } : { display: 'none' },
                  ]"
                  v-if="errors[0]"
                  >{{ errors[0].replace("The ", "") }}</span
                >
              </ValidationProvider>
            </div>
            <div class="form-group">
              <label>Emotion</label>
              <ValidationProvider
                name="Character characteristics"
                rules="required"
                v-slot="{ errors }"
              >
                <input
                  type="text"
                  class="form-control"
                  id="character-characteristics"
                  v-model="promptReactionEmotion"
                  :class="[isActive && errors[0] ? 'invalid' : '']"
                  placeholder="Add your Prompt Reaction Emotion"
                />
                <span
                  :class="[isActive ? 'invalid' : '']"
                  :style="[
                    isActive ? { display: 'block' } : { display: 'none' },
                  ]"
                  v-if="errors[0]"
                  >{{ errors[0].replace("The ", "") }}</span
                >
              </ValidationProvider>
            </div>
          </div>
          <button
            href="javascript:void(0);"
            class="btn primary"
            @click="saveLO('drafted', 1)"
          >
            Save Prompt Reaction Settings
          </button>
        </div> -->
        <div class="prompts-container">
          <div class="prompts-header mb-3">
            <h4>Lo Prompts</h4>
          </div>
          <div class="prompts-body">
            <div class="side-menu">
              <draggable v-model="promptData" @start="isDragging = true" @end="onEnd">
                <ul class="side-prompt" v-for="(prompt, index) in promptData" :key="prompt.id">
                  <li @click="choosePrompt(index)" class="menu-item">
                    {{ prompt.title || `Prompt: ${prompt.id}` }}
                  </li>
                  <button class="delete-button" @click="deletePrompt(prompt.id)">
                    X
                  </button>
                </ul>
              </draggable>
              <hr />
              <button class="btn add-prompt-btn" @click="addPrompt">
                Add Prompt
              </button>
            </div>
            <div class="prompts-edit" v-if="showPrompts">
              <div class="edit-body">
                <h4>Knowledge base form</h4>
                <form class="kb_form">
                  <div class="kb_form--item">
                    <label>Editing:
                      {{ this.chosenPrompt.title || "New Prompt" }}</label>
                  </div>
                  <div class="kb_form--item form-group">
                    <label>Prompt Title</label>
                    <textarea class="form-control" v-model="chosenPrompt.title" placeholder="Input prompt Title here" />
                  </div>
                  <div class="kb_form--item form-group">
                    <label>Independent / Dependendent Prompt</label>
                    <select class="custom-select" v-model="chosenPrompt.independent_prompt">
                      <option v-for="option in optionsIndependentDependent" :key="option.id" :value="option.value"
                        :selected="option.value === chosenPrompt.independent_prompt
          ">
                        {{ option.text }}
                      </option>
                    </select>
                  </div>
                  <div class="kb_form--item form-group">
                    <label>Prompt Type</label>
                    <select class="custom-select" @change="setPassFail($event)">
                      <option v-for="option in optionsPassFail" :key="option.id" :value="option.value"
                        :selected="option.value == chosenPassFail.value">
                        {{ option.text }}
                      </option>
                    </select>
                  </div>
                  <div class="kb_form--item form-group">
                    <label>Add Prompt</label>
                    <textarea class="form-control" v-model="chosenPrompt.prompt" placeholder="Input prompt here" />
                  </div>
                  <div class="kb_form--item form-group">
                    <label>Tone/Triggers</label>
                    <label>Trigger Response</label>
                    <select class="custom-select" ref="trigger_response" v-model="chosenPrompt.trigger_response">
                      <option disabled value="">
                        Please select a response
                      </option>
                      <option>Hint</option>
                      <option>Answer</option>
                      <option>None</option>
                    </select>
                    <label>Trigger Pass</label>
                    <select class="custom-select" ref="trigger_pass" v-model="chosenPrompt.trigger_pass">
                      <option disabled value="">
                        Please select a Pass trigger
                      </option>
                      <option>Sadness</option>
                      <option>Happiness</option>
                      <option>Fear</option>
                      <option>Anger</option>
                      <option>Surprise</option>
                      <option>Rude</option>
                      <option>None</option>
                    </select>
                    <label>Trigger Fail</label>
                    <select class="custom-select" ref="trigger_fail" v-model="chosenPrompt.trigger_fail">
                      <option disabled value="">
                        Please select a Fail trigger
                      </option>
                      <option>Sadness</option>
                      <option>Happiness</option>
                      <option>Fear</option>
                      <option>Anger</option>
                      <option>Surprise</option>
                      <option>Rude</option>
                      <option>None</option>
                    </select>
                  </div>
                  <div class="kb_form--item form-group">
                    <label>Prompt Narrative Knowledge Base</label>
                    <textarea v-model="chosenPrompt.narrative_knowledge_base"
                      placeholder="Input Prompt Narrative Knowledge Base here" />
                  </div>
                  <div class="kb_form--item form-group">
                    <label>Admin Notes</label>
                    <textarea class="form-control" v-model="chosenPrompt.admin_notes"
                      placeholder="Input Admin Notes here" />
                  </div>
                  <div class="kb_form--item form-group form-checkbox">
                    <input class="form-check-input" type="checkbox" value="" id="includePrompt"
                      v-model="chosenPrompt.optional_assessment">
                    <div>
                      <label class="form-check-label" for="includePrompt">
                        Include this prompt in assessment
                      </label>
                      <p>
                        Checking this option will include this prompt when
                        assessing the entire ELM
                      </p>
                    </div>
                  </div>
                  <div class="kb_form--item form-group">
                    <label>Feedback Request Type</label>
                    <div class="feedback-request-help-container">
                      <p class="feedback-request-help">
                        This option determines how much feedback is provided to
                        the user
                      </p>
                      <p class="feedback-request-help">
                        If "All" is selected, both the prompt text and the
                        appropriate feedback request item will be provided.
                      </p>
                      <p class="feedback-request-help">
                        If "Hit" is selected, the appropriate feedback request
                        item will be provided along with any part of the prompt
                        text that the user successfully hit on.
                      </p>
                      <p class="feedback-request-help">
                        If "None" is selected, only the appropriate feedback
                        request item will be provided.
                      </p>
                    </div>
                    <select ref="feedback_request_type" v-model="chosenPrompt.feedback_assessment_type">
                      <option disabled value="">
                        Please select a feedback request type
                      </option>
                      <option>All</option>
                      <option v-if="chosenPrompt.pass_fail === false">
                        Hit
                      </option>
                      <option>None</option>
                    </select>
                  </div>
                  <div class="kb_form--item form-group" v-if="chosenPrompt.pass_fail === true">
                    <label>Feedback Request Items</label>
                    <ul>
                      <li class="kb kb-feedback-request">
                        <label>Pass</label>
                        <div class="kb-feedback-request-input-container">
                          <input type="text" class="form-control" placeholder="Input Pass Item Label"
                            v-model="feedback_assessment_pass_data.label" />
                          <input type="text" class="form-control" placeholder="Input Pass Item Description"
                            v-model="feedback_assessment_pass_data.description" />
                        </div>
                      </li>
                      <li class="kb kb-feedback-request">
                        <label>Fail</label>
                        <div class="kb-feedback-request-input-container">
                          <input type="text" class="form-control" placeholder="Input Fail Item Label"
                            v-model="feedback_assessment_fail_data.label" />
                          <input type="text" class="form-control" placeholder="Input Fail Item Description"
                            v-model="feedback_assessment_fail_data.description" />
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="kb_form--item form-group" v-if="!chosenPrompt.pass_fail" :key="assessmentItemsKey">
                    <h5 class="assessmentFeedbackTitle">
                      Feedback Request Items
                      <button class="btn primary" @click="addNewAssessmentItem">Add New Feedback Request Item</button>
                    </h5>
                    <div class="assessment-feedback-empty" v-if="Object.keys(assessmentItems).length === 0">
                      <p>No feedback request items have been added yet.</p>
                      <p>Click the button above to add a new feedback request item.</p>
                    </div>
                    <ul>
                      <li class="kb kb-feedback-request" v-for="(item, index) in assessmentItems" :key="index"
                        ref="assessment_items">
                        <div class="kb-feedback-request-input-container">
                          <div>
                            <label>Assessment Item Label</label>
                            <input type="text" class="form-control" placeholder="Input Assessment Item Label"
                              v-model="item.label" :ref="`assessment-item-label-${index}`" />
                            <label>Assessment Item Description</label>
                            <textarea class="form-control" placeholder="Input Assessment Item Description"
                              v-model="item.description" :ref="`assessment-item-description-${index}`"></textarea>
                            <label>Item Level</label>
                            <input type="number" class="form-control" placeholder="Input Assessment Item Level"
                              :ref="`assessment-item-level-${index}`" v-model="item.assessment_level" />
                          </div>
                          <div class="feedback-item-btn-container">
                            <button class="btn primary" :ref="`assessment-item-${index}`"
                              @click="editAssessmentItem(index)">
                              Edit
                            </button>
                            <button class="btn delete-kb" @click="deleteAssessmentItem(index)">
                              Delete
                            </button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div v-if="addingNewAssessmentItem">
                    <div class="divider"></div>
                    <div class="form-group new-assessment-item-container">
                      <p>New Feedback Request Item</p>
                      <div class="new-assessment-form">
                        <label>Assessment Item Label</label>
                        <input type="text" class="form-control" placeholder="Input Assessment Item Label"
                          v-model="newItem.label" />
                        <label>Assessment Item Description</label>
                        <textarea class="form-control" placeholder="Input Assessment Item Description"
                          v-model="newItem.description" :ref="`new-assessment-item-description`"></textarea>
                        <label>Item Level</label>
                        <input type="number" class="form-control" placeholder="Input Assessment Item Level"
                          :ref="`new-assessment-item-level`" v-model="newItem.assessment_level" />
                      </div>
                      <div class="feedback-item-btn-container">
                        <button class="btn primary" @click="saveAssessmentItem()">
                          Save
                        </button>
                        <button class="btn secondary" @click="cancelAssessmentItem()">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                  <h5 v-if="!chosenPrompt.pass_fail" class="assessmentKBTitle">
                    Assessment Knowledge Base
                  </h5>
                  <div v-if="!chosenPrompt.pass_fail" class="kb_form--item form-group">
                    <label>Name</label>
                    <input type="text" ref="assessment_knowledgebase_name" class="form-control"
                      v-model="chosenPrompt.assessment_knowledgebase_name"
                      placeholder="Input Assessment Knowledge Base Name here" />
                  </div>
                  <div v-if="!chosenPrompt.pass_fail" class="kb_form--item form-group">
                    <div>
                      <label>Knowledge Base Entries</label>
                      <ul>
                        <li class="kb" v-for="(
                            assessment_kb, index
                          ) in chosenPrompt.knowledge_base_items" v-bind:key="index" ref="kb_entries">
                          <div class="entry-kb" :ref="`kbitem-${index}`">
                            {{ assessment_kb }}
                          </div>
                          <div class="kb-btn-container">
                            <button class="btn edit-kb" :ref="`kbedit-${index}`" @click="editKnowledgeBaseItem(index)">
                              Edit
                            </button>
                            <button class="btn delete-kb" @click="deleteKnowledgeBaseItem(index)">
                              Delete
                            </button>
                          </div>
                        </li>
                      </ul>
                      <div class="kb-input form-group">
                        <input class="form-control" type="text" v-model="newAssKB"
                          placeholder="Input Assessment Knowledge Base here" />
                        <button class="btn primary" @click="addKnowledgeBaseItem">
                          Add Knowledge Base Entry
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="kb_form--item form-group">
                    <label>Task</label>
                    <input class="form-control" type="text" placeholder="Input task here" v-model="chosenPrompt.task" />
                  </div>
                  <div class="kb_form--item kb_form--itemhori">
                    <div class="kb_item--column form-group" v-if="!chosenPrompt.pass_fail">
                      <label>Threshold</label>
                      <input ref="thresholdRequired" class="form-control" type="text"
                        v-model="chosenPrompt.threshold" />
                    </div>
                    <div class="kb_item--column form-group">
                      <label>Mandatory</label>
                      <input class="form-control" type="checkbox" v-model="chosenPrompt.mandatory" />
                    </div>
                    <div class="kb_item--column">
                      <label>Question Needed</label>
                      <input type="checkbox" v-model="chosenPrompt.question_needed" />
                    </div>
                  </div>
                  <button class="btn primary" @click="savePrompt">
                    Save Prompt
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="setup-content" v-if="isActiveTab == 'chat-testing'">
        <div class="testing-header">
          <h4 class="head-title">{{ name }}</h4>
          <h5 v-html="description"></h5>
        </div>
        <div class="learner-container">
          <div>
            <div class="messages-area" v-for="message in messages" :key="message.id">
              <transition name="up">
                <div v-if="message.question" class="bubble robot-bubble">
                  {{ message.question }}
                </div>
              </transition>
              <transition name="up">
                <div v-if="message.answer" class="bubble learner-bubble">
                  {{ message.answer }}
                </div>
              </transition>
              <transition name="up">
                <div v-if="message.response" class="bubble robot-bubble">
                  {{ message.response }}
                </div>
              </transition>
            </div>
          </div>
          <transition name="fade">
            <div v-if="hasStarted" class="spacer"></div>
          </transition>
          <div class="chat">
            <transition name="fade">
              <div v-if="loading">Your answer is being processed.</div>
            </transition>
            <transition name="fade">
              <div v-if="assessmentLoading">Your assessment is processing.</div>
            </transition>
            <input :disabled="loading || assessmentLoading" class="input-area" type="text" v-model="userAnswer" />
            <div class="button-container">
              <button :disabled="loading || assessmentLoading" class="button" @click="submitMessage">
                Submit your message
              </button>
              <button v-if="hasStarted" :disabled="loading || assessmentLoading" class="button"
                @click="submitAssessmentRequest">
                Get Conversation Assessment
              </button>
            </div>
          </div>
          <div class="feedback" v-if="feedback">
            <transition name="fade">
              <h2>Your Feedback</h2>
            </transition>
            <transition name="fade">
              <p>{{ feedback }}</p>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { utilFunctionService } from "@/utils/utils.service";
import api from "../../services/api";
import { commonConfig } from "@/utils/commonConfig";
import draggable from "vuedraggable";
import LlmChatSkillAssessment from "./llm-chat-components/LlmChatSkillAssessment.vue";
import { VueEditor } from "vue2-editor";
export default {
  name: "llm-chat",
  components: {
    draggable,
    LlmChatSkillAssessment,
    VueEditor,
  },
  props: ["llmType"],
  data() {
    return {
      status: "drafted",
      isActiveTab: "basic-information",
      chatContentId: "",
      errors: "",
      isActive: false,
      name: "",
      adminNotes: "",
      description: "",
      displayOverallAssessment: false,
      userAnswer: "",
      optionsPassFail: [
        { text: "Pass/Fail", value: "pass_fail" },
        { text: "Threshold", value: "threshold" },
      ],
      optionsIndependentDependent: [
        { text: "Independent", value: true },
        { text: "Dependent", value: false },
      ],
      optionsCharacteristics: [{ text: "Open" }, { text: "Closed" }],
      optionsFeedbackType: [{ text: "long" }, { text: "short" }],
      dialogicQuestions: [],
      numberOfAttempts: 0,
      attemptsLimit: 3,
      chosenPrompt: {},
      chosenPromptIndex: 0,
      generalKnowledgeBase: "",
      narrativeKnowledgeBase: "",
      newAssKB: "",
      promptData: [],
      finishedLoading: false,
      chosenPromptKey: "",
      messages: [
        {
          question: "",
          answer: "",
          response: "",
        },
      ],
      loading: false,
      assessmentLoading: false,
      feedback: "",
      hasStarted: false,
      latestResponse: {},
      showPrompts: false,
      characterName: "",
      characterPronouns: "",
      characterAge: "",
      characterJob: "",
      characterStance: "",
      characterCharacteristics: "",
      promptReactionSentiment: "",
      promptReactionEmotion: "",
      narrativeKnowledgeBaseAmount: 0,
      chosenPassFail: "Pass",
      dialogicQuestionRequired: false,
      llmEmailIterations: 0,
      lastMessage: "",
      lastResponse: "",
      emailBody: "",
      emailSubject: "",
      emailChain: [],
      emailToCharacter: "",
      emailFromCharacter: "",
      dialogicQuestionKey: 0,
      assessmentItems: {},
      assessmentItemsKey: 0,
      feedback_assessment_pass_data: {
        label: "",
        description: "",
      },
      feedback_assessment_fail_data: {
        label: "",
        description: "",
      },
      newItem: {
        label: "",
        description: "",
        assessment_level: 0,
      },
      addingNewAssessmentItem: false,
      ccCharacters: [],
      newccCharacter: "",
      enableVoice: false,
      enableAvatar: false,
      showChatLog: false,
      reattemptsEnabled: false,
      maxReattempts: 0,
      enableLlmAssessments: false,
      characterImage: "",
      characterImageURL: "",
      uploadImageStatus: false,
      image: "",
      characterFeedbackType: "",
    };
  },
  methods: {
    editKnowledgeBaseItem(index) {
      const kbItem = this.$refs[`kbitem-${index}`][0];
      const kbItemButton = this.$refs[`kbedit-${index}`][0];
      kbItemButton.classList.add("kb-edit-active");
      kbItemButton.innerText = "Save Edit";
      kbItem.contentEditable = true;
      kbItem.focus();
      kbItemButton.addEventListener("click", () => {
        kbItem.contentEditable = false;
        kbItemButton.innerText = "Edit";
        kbItemButton.classList.remove("kb-edit-active");
        this.chosenPrompt.knowledge_base_items[index] = kbItem.innerText;
        this.savePrompt();
      });
    },
    deleteKnowledgeBaseItem(index) {
      this.chosenPrompt.knowledge_base_items.splice(index, 1);
    },
    setCharacteristics(e) {
      console.log(e.target.value)
      this.characterCharacteristics = e.target.value;
    },
    setFeedbackType(e) {
      console.log(e.target.value)
      this.characterFeedbackType = e.target.value;
    },
    editAssessmentItem(index) {
      // This method needs to be worked around as the event listener
      // Removes the one already attached to the edit button
      const assessmentItemLabel = this.$refs[`assessment-item-label-${index}`][0];
      const assessmentItemDescription = this.$refs[`assessment-item-description-${index}`][0];
      const assessmentItemLevel = this.$refs[`assessment-item-level-${index}`][0];
      const assessmentItemButton = this.$refs[`assessment-item-${index}`][0];
      assessmentItemButton.classList.add("kb-edit-active");
      assessmentItemButton.innerText = "Save Edit";
      assessmentItemLabel.contentEditable = true;
      assessmentItemDescription.contentEditable = true;
      assessmentItemLevel.contentEditable = true;
      assessmentItemLabel.focus();
      assessmentItemButton.addEventListener("click", () => {
        assessmentItemLabel.contentEditable = false;
        assessmentItemDescription.contentEditable = false;
        assessmentItemLevel.contentEditable = false;
        assessmentItemButton.innerText = "Edit";
        assessmentItemButton.classList.remove("kb-edit-active");
        this.savePrompt();
      }, { once: true });
    },
    deleteAssessmentItem(index) {
      delete this.assessmentItems[index]
      this.assessmentItemsKey++;
      this.savePrompt();
    },
    saveAssessmentItem() {
      let assessmentItemLength = Object.keys(this.assessmentItems).length;
      this.assessmentItems[assessmentItemLength] = this.newItem;
      this.newItem = {
        label: "",
        description: "",
        assessment_level: 0,
      }
      this.addingNewAssessmentItem = false;
      this.savePrompt();
    },
    cancelAssessmentItem() {
      this.newItem = {
        label: "",
        description: "",
        assessment_level: 0,
      }
      this.addingNewAssessmentItem = false;
    },
    addNewAssessmentItem() {
      this.addingNewAssessmentItem = true;
    },
    switchTab(tab) {
      this.refreshNewData();
      this.isActiveTab = tab;
      this.chatContentId = tab;
    },
    refreshNewData() {
      api.fetchLoDetails(this.$route.params.id, this.$route.query.id).then(res => {
        this.refreshData(res.data);
      });
    },
    addKnowledgeBaseItem() {
      if (this.newAssKB === "") return;
      this.chosenPrompt.knowledge_base_items.push(this.newAssKB);
      this.newAssKB = "";
    },
    archiveLO() {
      this.$emit("archiveLO");
    },
    addPrompt() {
      let newPrompt = {
        new_prompt: true,
        title: "",
        prompt: "",
        assessment_knowledgebase_name: "",
        knowledge_base_items: [],
        threshold: 0,
        mandatory: false,
        question_needed: false,
        task: "",
        narrative_knowledge_base: "",
        admin_notes: "",
        pass_fail: true
      };
      this.promptData.push(newPrompt);
      this.chosenPromptIndex = this.promptData.length - 1;
      this.chosenPrompt = this.promptData[this.chosenPromptIndex];
      this.showPrompts = true;
    },
    choosePrompt(index) {
      this.chosenPromptIndex = index;
      this.chosenPrompt = this.promptData[this.chosenPromptIndex];
      this.assessmentItems = this.chosenPrompt.assessment_items;
      this.assessmentItems = this.chosenPrompt.assessment_items;
      if (this.chosenPrompt.pass_fail === true) {
        this.feedback_assessment_pass_data =
          this.chosenPrompt.assessment_items[0] || {};
        this.feedback_assessment_fail_data =
          this.chosenPrompt.assessment_items[1] || {};
        this.chosenPassFail = { text: "Pass/Fail", value: "pass_fail" };
      } else {
        this.chosenPassFail = { text: "Threshold", value: "threshold" };
      }
      this.showPrompts = true;
    },
    deletePrompt(index) {
      api
        .deletePrompt(index)
        .then((res) => {
          console.log(res);
          utilFunctionService.hideLoader();
        })
        .catch();
    },
    getPrompts() {
      this.finishedLoading = false;
      api
        .getPrompts(this.llmChatData.attributes.card_detail.id)
        .then((res) => {
          console.log(res);
          res.data.data.forEach((prompt) => {
            console.log(prompt.attributes);
            this.promptData.push(prompt.attributes);
          });
          this.promptData = res.data.data;
          if (this.promptData.length > 0) {
            this.chosenPrompt = this.promptData[0];
            this.showPrompts = true;
          }
          this.finishedLoading = true;
          utilFunctionService.hideLoader();
        })
        .catch();
    },
    savePrompt() {
      const loID = this.llmChatData.attributes.card_detail.id;
      const promptID = this.promptData[this.chosenPromptIndex].id;
      if (this.chosenPrompt.pass_fail === true) {
        this.assessmentItems = {
          0: this.feedback_assessment_pass_data,
          1: this.feedback_assessment_fail_data,
        };
      }
      let prompt_data = {
        title: this.chosenPrompt.title,
        prompt: this.chosenPrompt.prompt,
        assessment_knowledgebase_name:
          this.chosenPrompt.assessment_knowledgebase_name,
        knowledge_base: this.chosenPrompt.knowledge_base,
        pass_fail: this.chosenPrompt.pass_fail,
        threshold: this.chosenPrompt.threshold,
        mandatory: this.chosenPrompt.mandatory,
        task: this.chosenPrompt.task,
        question_needed: this.chosenPrompt.question_needed,
        knowledge_base_items: this.chosenPrompt.knowledge_base_items,
        narrative_knowledge_base: this.chosenPrompt.narrative_knowledge_base,
        admin_notes: this.chosenPrompt.admin_notes,
        trigger_fail: this.chosenPrompt.trigger_fail,
        trigger_pass: this.chosenPrompt.trigger_pass,
        trigger_response: this.chosenPrompt.trigger_response,
        independent_prompt: this.chosenPrompt.independent_prompt,
        feedback_assessment_type: this.chosenPrompt.feedback_assessment_type,
        assessment_items: this.assessmentItems,
        optional_assessment: this.chosenPrompt.optional_assessment,

      };
      if (this.chosenPrompt.new_prompt) {
        api
          .addPrompt(loID, prompt_data)
          .then((res) => {
            console.log(res);
            utilFunctionService.hideLoader();
            utilFunctionService.showSuccess("Prompt saved successfully");
          })
          .catch();
      } else {
        api
          .updatePrompt(promptID, prompt_data)
          .then((res) => {
            console.log(res);
            utilFunctionService.hideLoader();
            utilFunctionService.showSuccess("Prompt saved successfully");
          })
          .catch();
      }
    },
    setPassFail(e) {
      if (e.target.value == "pass_fail")
        this.chosenPrompt.pass_fail = true;
      else if (e.target.value == "threshold")
        this.chosenPrompt.pass_fail = false;
    },
    saveKnowledgeBases(general, narrative) {
      this.generalKnowledgeBase = general;
      this.narrativeKnowledgeBase = narrative;
      console.log(this.generalKnowledgeBase, this.narrativeKnowledgeBase);
      console.log(this.characterStance);
      this.saveLO('drafted', 1)
    },
    saveLO(type) {
      utilFunctionService.showLoader();
      const fb = new FormData();
      fb.append("learning_object[status]", type);
      fb.append("learning_object[name]", this.name);
      fb.append("learning_object[admin_notes]", this.adminNotes);
      fb.append("learning_object[description]", this.description);
      fb.append("card_type", this.llmType);
      fb.append("card[llm_type]", this.llmType);
      fb.append("learning_object[learning_object_type]", this.llmType);
      fb.append("card[name]", this.name);
      fb.append("card[description]", this.description);
      fb.append("card[admin_notes]", this.adminNotes);
      fb.append("card[general_knowledge_base]", this.generalKnowledgeBase);
      fb.append("card[narrative_knowledge_base]", this.narrativeKnowledgeBase);
      fb.append("card[character_name]", this.characterName);
      fb.append("card[character_age]", this.characterAge);
      fb.append("card[character_job]", this.characterJob);
      if (this.characterStance == "") {
        fb.append("card[character_stance]", "correct");
      } else {
        fb.append("card[character_stance]", this.characterStance);
      }
      fb.append("card[character_stance]", this.characterStance);
      fb.append("card[character_pronouns]", this.characterPronouns);
      fb.append("card[characteristics]", this.characterCharacteristics);
      fb.append("card[feedback_type]", this.characterFeedbackType);
      fb.append("card[email_iteration_limit]", this.llmEmailIterations);
      fb.append("card[enable_avatar]", this.enableAvatar);
      fb.append("card[show_chat_log]", this.showChatLog);
      fb.append("card[cc_characters][]", []);
      fb.append("card[questions]", "");
      fb.append("card[llm_chat_image_enabled]", false);
      fb.append("card[enable_voice]", this.enableVoice);
      fb.append("card[enable_reattempts]", this.reattemptsEnabled);
      fb.append("card[max_reattempts]", this.maxReattempts);
      fb.append("card[enable_llm_assessment]", this.enableLlmAssessments);
      if (this.llmChatImageEnabled === true) {
        fb.append("card[llm_chat_image_enabled]", this.llmChatImageEnabled);
        fb.append("card[llm_chat_image]", this.llmChatImage);
      } else {
        fb.append("card[llm_chat_image_enabled]", false);
      }
      if (this.characterImage) {
        fb.append("card[character_image]", this.characterImage);
      }
      fb.append(
        "card[prompt_reaction_sentiment]",
        this.promptReactionSentiment
      );
      fb.append("card[prompt_reaction_emotion]", this.promptReactionEmotion);
      if (this.$route.query.id) {
        api
          .updateLo(this.$route.params.id, this.$route.query.id, fb)
          .then((res) => {
            console.log(res);
            utilFunctionService.hideLoader();
            utilFunctionService.showSuccess(
              type === "drafted"
                ? commonConfig.appMessages.LoDraftText
                : commonConfig.appMessages.LoPublichedText
            );
            this.refreshImage(res.data.data.attributes.card_detail.attributes);
          })
          .catch();
      } else {
        api
          .createLearningObject(this.$route.params.id, fb)
          .then((res) => {
            console.log(res);
            utilFunctionService.hideLoader();
            utilFunctionService.showSuccess(
              type === "drafted"
                ? commonConfig.appMessages.LoDraftText
                : commonConfig.appMessages.LoPublichedText
            );
            window.location.reload();
          })
          .catch();
      }
    },
    publishLO(type) {
      utilFunctionService.showLoader();
      const fb = new FormData();
      fb.append("learning_object[status]", type);
      fb.append("learning_object[name]", this.name);
      fb.append("learning_object[admin_notes]", this.adminNotes);
      fb.append("learning_object[description]", this.description);
      fb.append("card_type", "llm_chat");
      fb.append("card[llm_type]", this.llmType);
      fb.append("learning_object[learning_object_type]", "llm_chat");
      fb.append("card[name]", this.name);
      fb.append("card[description]", this.description);
      fb.append("card[admin_notes]", this.adminNotes);
      fb.append("card[general_knowledge_base]", this.generalKnowledgeBase);
      fb.append("card[narrative_knowledge_base]", this.narrativeKnowledgeBase);
      fb.append("card[character_name]", this.characterName);
      fb.append("card[character_age]", this.characterAge);
      fb.append("card[character_pronouns]", this.characterPronouns);
      fb.append("card[character_job]", this.characterJob);
      if (this.characterStance == "") {
        fb.append("card[character_stance]", "correct");
      } else {
        fb.append("card[character_stance]", this.characterStance);
      }
      fb.append("card[characteristics]", this.characterCharacteristics);
      fb.append("card[feedback_type]", this.F);
      fb.append("card[email_iteration_limit]", this.llmEmailIterations);
      fb.append("card[cc_characters][]", []);
      fb.append("card[questions]", "");
      fb.append("card[llm_chat_image_enabled]", false);
      fb.append("card[enable_avatar]", this.enableAvatar);
      fb.append("card[show_chat_log]", this.showChatLog);
      fb.append("card[enable_voice]", this.enableVoice);
      fb.append("card[enable_reattempts]", this.reattemptsEnabled);
      fb.append("card[max_reattempts]", this.maxReattempts);
      fb.append("card[enable_llm_assessment]", this.enableLlmAssessments);
      if (this.llmChatImageEnabled === true) {
        fb.append("card[llm_chat_image_enabled]", this.llmChatImageEnabled);
        fb.append("card[llm_chat_image]", this.llmChatImage);
      } else {
        fb.append("card[llm_chat_image_enabled]", false);
      }
      if (this.characterImage) {
        fb.append("card[character_image]", this.characterImage);
      }
      fb.append(
        "card[prompt_reaction_sentiment]",
        this.promptReactionSentiment
      );
      fb.append("card[prompt_reaction_emotion]", this.promptReactionEmotion);
      if (this.$route.query.id) {
        api
          .updateLo(this.$route.params.id, this.$route.query.id, fb)
          .then((res) => {
            console.log(res);
            utilFunctionService.hideLoader();
            utilFunctionService.showSuccess(
              type === "published"
                ? commonConfig.appMessages.LoDraftText
                : commonConfig.appMessages.LoPublichedText
            );
            this.refreshImage(res.data.data.attributes.card_detail.attributes);
          })
          .catch();
      } else {
        api
          .createLearningObject(this.$route.params.id, fb)
          .then((res) => {
            console.log(res);
            utilFunctionService.hideLoader();
            utilFunctionService.showSuccess(
              type === "published"
                ? commonConfig.appMessages.LoDraftText
                : commonConfig.appMessages.LoPublichedText
            );
            window.location.reload();
          })
          .catch();
      }
    },
    submitMessage() {
      this.messages[this.messages.length - 1].answer = this.userAnswer;
      this.submitQuestion();
      this.userAnswer = "";
    },
    populateNarrativeKnowledgeBase() {
      let populatedNarrativeKnowledgeBase = [];
      populatedNarrativeKnowledgeBase.push(this.narrativeKnowledgeBase);
      this.promptData.forEach((prompt) => {
        populatedNarrativeKnowledgeBase.push(prompt.narrative_knowledge_base);
      });
      return populatedNarrativeKnowledgeBase;
    },
    submitQuestion() {
      utilFunctionService.showLoader();
      const question = this.messages[this.messages.length - 1].question;
      const answer = this.messages[this.messages.length - 1].answer;
      this.lastQuestion = question;
      this.lastAnswer = answer;
      let genKnowledgeBase = [];
      genKnowledgeBase.push(this.generalKnowledgeBase);
      let narrKnowledgeBase = this.populateNarrativeKnowledgeBase();
      this.loading = true;
      this.hasStarted = true;
      this.promptData[0].threshold = 0;
      let payload = {
        character_data: {
          age: this.characterAge,
          name: this.characterName,
          job: this.characterJob,
          characteristic: this.characterCharacteristics,
          student_name: "Tom",
        },
        student_id: 1,
        lo_id: 1,
        data: {
          narrative_knowledge_base: narrKnowledgeBase,
          general_knowledge_base: genKnowledgeBase,
          prompt_data: this.promptData,
          question: question,
          student_answer: answer,
          number_attempts: this.numberOfAttempts,
          attempt_limit: this.attemptsLimit,
          prompt_reaction_sentiment: this.promptReactionSentiment,
          prompt_reaction_emotion: this.promptReactionEmotion,
        },
      };
      api
        .submitLlmChatQuestion(payload)
        .then((res) => {
          console.log(res);
          utilFunctionService.hideLoader();
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    submitAssessmentRequest() {
      this.assessmentLoading = true;
      let payload = {
        student_id: 1,
        lo_id: 1,
        data: {
          narrative_knowledge_base: this.narrativeKnowledgeBase,
          general_knowledge_base: this.generalKnowledgeBase,
          prompt_data: this.promptData,
          number_attempts: this.numberOfAttempts,
          attempt_limit: this.attemptsLimit,
        },
      };
      api.submitLlmChatAssessment(payload).then((res) => {
        console.log(res);
        this.assessmentLoading = false;
      });
    },
    resetLlmConversation() {
      let payload = {
        student_id: 1,
        lo_id: 1,
      }
      api.resetLlmConversation(payload).then((res) => {
        console.log(res);
        this.assessmentLoading = false;
      });
    },
    refreshData(newData) {
      console.log(newData);
      const cardDetail = newData.data.attributes.card_detail.attributes;
      this.name = cardDetail.title;
      this.adminNotes = cardDetail.admin_notes;
      this.description = cardDetail.description;
      this.narrativeKnowledgeBase = [];
      if (cardDetail.narrative_knowledge_base.length > 1) {
        this.narrativeKnowledgeBase.push(cardDetail.narrative_knowledge_base)
      } else {
        this.narrativeKnowledgeBase = [""];
      }
      this.narrativeKnowledgeBaseAmount = this.narrativeKnowledgeBase.length;
      this.generalKnowledgeBase = cardDetail.general_knowledge_base;
      this.characterName = cardDetail.character_name;
      this.characterAge = cardDetail.character_age;
      this.characterJob = cardDetail.character_job;
      this.characterPronouns = cardDetail.character_pronouns;
      this.characterStance = cardDetail.character_stance;
      this.characterCharacteristics = cardDetail.characteristics;
      this.promptReactionSentiment = cardDetail.prompt_reaction_sentiment;
      this.promptReactionEmotion = cardDetail.prompt_reaction_emotion;
      this.enableAvatar = cardDetail.enable_avatar;
      this.showChatLog = cardDetail.show_chat_log;
      this.enableVoice = cardDetail.enable_voice;
      this.reattemptsEnabled = cardDetail.enable_reattempt;
      this.maxReattempts = cardDetail.max_reattempts;
      this.enableLlmAssessments = cardDetail.enable_llm_assessment;
      this.characterImageURL = cardDetail.character_image_url;
      this.characterFeedbackType = cardDetail.feedback_type;
      if (cardDetail.pass_fail) {
        this.chosenPassFail = "pass";
      } else {
        this.chosenPassFail = "fail";
      }
      if (cardDetail.prompts) {
        this.promptData = cardDetail.prompts;
      } else {
        this.promptData = [];
      }
    },
    onEnd() {
      const fd = new FormData();
      this.promptData.map((data, index) => {
        fd.append("prompt[][id]", data.id);
        fd.append("prompt[][prompt_order]", index + 1);
      });
      api
        .reorderPrompts(this.llmChatData.attributes.card_detail.id, this.llmChatData.attributes.card_type, fd)
        .then(() => { })
        .catch();
    },
    uploadImage(event) {
      this.uploadImageStatus = true;
      console.log(event.target.files);
      this.characterImage = event.target.files[0];
      let _reader = null;
      _reader = new FileReader();
      _reader.readAsDataURL(this.characterImage);
      _reader.onload = (e) => {
        this.image = e.target.result;
      };
      this.saveLO(this.status);
    },
    refreshImage(cardDetail) {
      this.characterImageURL = cardDetail.character_image_url;
    },
    deleteCharacterImage() {
      api.removeLlmCharacterImage(this.$route.params.id, this.$route.query.id).then((response) => {
        console.log(response);
        this.characterImage = "";
        this.characterImageURL = "";
        this.$toast.open({
          message: "Character Image Deleted",
          type: "success",
        });
      });
    },
  },
  mounted() {
    this.llmChatData = this.$store.state.llmData;
    if (this.llmChatData !== "") {
      console.log("mounted")
      api.fetchLoDetails(this.$route.params.id, this.$route.query.id).then(res => {
        this.refreshData(res.data);
      });
    }
    document.addEventListener("keyup", (event) => {
      if (event.keyCode === 13 && this.userAnswer !== "" && !this.loading) {
        this.submitMessage();
      }
    });
    if (this.isActiveTab === 'chat-testing') this.resetLlmConversation();
    this.$cable.subscribe({
      channel: "AssessmentAnswerChannel",
      room: "public",
    });
    this.$cable.subscribe({
      channel: "AssessmentResultChannel",
      room: "public",
    });
  },
  channels: {
    AssessmentAnswerChannel: {
      connected() {
        console.log("I am connected.");
      },
      received(data) {
        if (this.llmType === "llm_chat") {
          if (data.data.student_id == 1) {
            this.numberOfAttempts = this.numberOfAttempts + 1;
            let responses = data.data.response;
            responses.forEach((response) => {
              this.messages[this.messages.length - 1].response = response;
              this.messages.push({
                answer: "",
                response: "",
              });
            });
          }
          this.loading = false;
        } else if (
          this.llmType === "dialogic" ||
          this.llmType === "llm_dialogic"
        ) {
          if (data.data.student_id == 1) {
            let responses = data.data.response;
            this.numberOfAttempts = this.numberOfAttempts + 1;
            responses.forEach((response) => {
              this.messages[this.messages.length - 1].response = response;
              this.messages.push({
                answer: "",
                response: "",
              });
            });
            this.loading = false;
          }
        } else if (this.llmType === "llm_email") {
          if (data.data.student_id == 1) {
            console.log("admin logged");
            this.numberOfAttempts = this.numberOfAttempts + 1;
            this.emailChain.push({
              fromCharacter: this.characterName,
              toCharacter: this.studentName,
              response: data.data.response,
              id: this.emailChain.length + 1,
            });
            utilFunctionService.hideLoader();
            this.loading = false;
          }
        }
      },
    },
    AssessmentResultChannel: {
      connected() {
        console.log("I am result connected.");
      },
      received(res) {
        console.log(res);
        console.log(res.data.student_id === 1);
        this.feedback = res.data.prompts.assessment_results;
        if (res.data.student_id === 1) {
          this.feedback = res.data.prompts.assessment_results;
        }
      },
    },
  },
};
</script>

<style>
.knowledge-base-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
}

.knowledge-base-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.knowledge-base-item h5 {
  font-weight: bold;
}

.kb-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.text-area {
  width: 400px;
  font-family: sans-serif;
  height: 200px;
  margin-bottom: 18px;
  padding: 8px;
  line-height: 1.6;
}

.prompts-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.side-menu {
  display: flex;
  flex-direction: column;
  width: 20%;
  border-right: 1px solid black;
}

.menu-item {
  width: 90px;
  border: 1px solid black;
  margin-bottom: 8px;
  border-radius: 5px;
  list-style-type: none;
  background: white;
  color: black;
  font-weight: bold;
  cursor: pointer;
  padding: 8px;
}

.menu-item:hover {
  background: black;
  color: white;
}

.prompts-edit {
  width: 80%;
  padding: 16px;
}

.prompts-container {
  width: 100%;
}

.prompts-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.kb_form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: flex-start;
}

.kb_form--item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px;
}

.kb_form--itemhori {
  flex-direction: row;
}

.kb_form--item label,
p {
  margin-bottom: 8px;
  font-weight: bold;
}

.kb_form--item textarea {
  width: 100%;
  font-family: sans-serif;
  height: 100px;
  margin-bottom: 18px;
  padding: 8px;
  line-height: 1.6;
}

.kb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.kb-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
}

.kb-input input {
  width: 50%;
  padding: 8px;
  border-radius: 5px;
}

.btn {
  border-radius: 5px;
  border: 1px solid black;
  background: white;
  color: black;
  font-weight: bold;
  cursor: pointer;
  padding: 8px;
  height: 50px;
}

.btn:hover {
  background: black;
  color: white;
}

.side-prompt {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.delete-button {
  background: red;
  color: white;
  font-weight: bold;
  cursor: pointer;
  padding: 5px;
  height: 25px;
  border-radius: 5px;
  border: 1px solid black;
}

.delete-button:hover {
  background: white;
  color: red;
  border: 1px solid red;
}

.kb_item--column {
  display: flex;
  flex-direction: column;
}

.learner-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.messages-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1000px;
}

.bubble {
  border-radius: 15px;
  padding: 8px;
  margin-bottom: 16px;
  width: 50%;
}

.learner-bubble {
  background: green;
  color: white;
  font-weight: bold;
  justify-self: flex-end;
  align-self: flex-end;
}

.robot-bubble {
  background: blue;
  color: white;
  font-weight: bold;
  justify-self: flex-start;
  align-self: flex-start;
}

.input-area {
  color: black;
  width: 400px;
}

.chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 32px;
}

.button {
  padding: 8px;
  border-radius: 5px;
  background: white;
  border: 2px solid green;
  margin-top: 16px;
}

.button:hover {
  background: green;
  color: white;
  cursor: pointer;
  transition: all 0.2s;
}

.button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.up-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}

.up-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
  transform: translateY(30px);
}

.feedback {
  width: 50%;
  margin: 25px auto;
  text-align: left;
  line-height: 1.6;
}

.spacer {
  display: block;
  width: 50%;
  margin: 0 auto;
  height: 1px;
  background: black;
}

.prompt-reaction-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.prompt-reaction-container .prompts-body {
  justify-content: flex-start;
  gap: 16px;
}

.prompt-reaction-container button {
  width: 250px;
}

.form-checkbox .form-check-input {
  position: relative;
  margin-left: 3px;
  margin-right: 18px;
}

.form-group {
  display: flex;
  flex-direction: column
}

.btn {
  border-radius: 5px;
  border: 1px solid black;
  background: white;
  color: black;
  font-weight: bold;
  cursor: pointer;
  padding: 8px;
  height: 50px;
}

.btn:hover {
  background: black;
  color: white;
}

.character-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 16px;
}

.capitalize-option {
  text-transform: capitalize;
}
</style>
